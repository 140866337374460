import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import ScrollAnimation from 'react-animate-on-scroll';

class Kontakt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            number: "",
            message: "",
            successMsg: ""
        };
    }
    handleForm = e => {
        let that = this;
        axios
            .post("https://formcarry.com/s/MvBu5TJSORw", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                document.getElementById("contactForm").reset();
                that.setState({
                    successMsg: "Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden."
                });
                document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    
    render() {
        return (
            <React.Fragment>
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                        </div>
                        </div>

                        <div className="row">
                            

                            <div className="col-lg-12 col-md-8">
                                <div className="contact-form">
                                    <ValidationForm
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="name"
                                                        id="name"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Bitte geben Sie Ihren Namen ein"
                                                        className="form-control"
                                                        placeholder="Ihr Name"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Bitte geben Sie Ihre E-Mail ein"
                                                        className="form-control"
                                                        placeholder="Ihre E-Mail"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="subject"
                                                        id="subject"
                                                        type="text"
                                                        successMessage=""
                                                        errorMessage="Bitte geben Sie Ihre Postleitzahl ein"
                                                        className="form-control"
                                                        placeholder="Ihre Postleitzahl"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="number"
                                                        id="number"
                                                        type="text"
                                                        successMessage=""
                                                        errorMessage="Bitte geben Sie Ihre Telefonnummer ein"
                                                        className="form-control"
                                                        placeholder="Ihre Telefonnummer"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="message"
                                                        id="description"
                                                        multiline
                                                        placeholder="Ihre Nachricht"
                                                        className="form-control"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Bitte geben Sie Ihre Nachricht ein"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap">
                                            <div className="button">
                                                <button type="submit">Abschicken <Icofont icon="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                    </ValidationForm>
                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                <h3 className="contactMsg">
                                                    {this.state.successMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
Kontakt.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Kontakt.defaultProps = {
    SectionbgTitle: "Kontakt",
    sectionTitle: "Kontaktformular",
    sectionDescription: "Für eine schnelle Kontaktaufnahme füllen Sie bitte die nachfolgenden Felder aus",
};
export default Kontakt;
