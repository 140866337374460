
import React, { Component } from "react";
import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel3";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

class WaendeFarben extends Component {
    render() {
        //Partner loop start
        const partnerData = this.props.partnersData.map((partner, index) => (
            <div className="single-partner-logo" key={index}>
                <Link to={partner.partnerLink} className="logo-preview">
                    <img src={partner.partnerLogo} alt="partnerLogo" />
                </Link>
            </div>
        ));
        //Partner loop END
        return (
            <React.Fragment>
                <section className="our-partners ptb-100">
                  <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="row">
                            <OwlCarousel
                                className="owl-theme partners-slides"
                                dots= {false}
                                loop={true}
                                margin={100}
                                autoplay={true}
                                smartSpeed={1000}
                                nav={true}
                                navText={[
                                    "<i class='icofont-arrow-left'></i>",
                                    "<i class='icofont-arrow-right'></i>"
                                ]}
                                responsive={{
                                    0: { items: 1 },
                                    768: {
                                        items: 3
                                    },
                                    1200: {
                                        items: 5
                                    }
                                }}
                            >
                                {partnerData}
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
WaendeFarben.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    partnersData: PropTypes.array
};

//Default Props
WaendeFarben.defaultProps = {
    SectionbgTitle: "",
    sectionTitle: "Farbvielfalt",
    sectionDescription: "Mit über 30 verschiedenen Farbrichtungen für Ihre Wände kann Ihr neues Bad genau Ihren Farbgeschmack treffen.",
    partnersData: [
        {
            partnerLogo: require("../assets/img/wand-farben/img1.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img2.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img3.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img4.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img5.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img6.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img7.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img8.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img9.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img10.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img11.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img12.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img13.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img14.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img15.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img16.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img17.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img18.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img19.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img20.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img21.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img22.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img23.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img24.png"),
            partnerLink: "/#0"
        },
        {
                partnerLogo: require("../assets/img/wand-farben/img25.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img26.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img27.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img28.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img29.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img30.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img31.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img32.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/wand-farben/img33.png"),
            partnerLink: "/#0"
        }




    ]
};
export default WaendeFarben;
