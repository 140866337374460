import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

class BoedenBar extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="cta-area ctab-bg-img">
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <ScrollAnimation animateIn="fadeInUp">
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
BoedenBar.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
       
};

//Default Props
BoedenBar.defaultProps = {
    Title: "",
    Content: "",
    BtnLink: "/#0",
    BtnName: "",
};
export default BoedenBar;
