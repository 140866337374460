import React, { Component } from "react";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                    <ul className="list-inline">
                                        <li><a href="mailto:kontakt@derbadmax.de" style={{color:"white"}}><Icofont icon="icofont-email"/> kontakt@derbadmax.de </a></li>
                                        <li><a href="callto:+4915142679726" style={{color:"white"}}><Icofont icon="icofont-ui-call" />+49 151 426 797 26</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-5">
                                <ul className="list-inline">
                                    <li><Link to="/impressum" style={{color:"white"}}>Impressum</Link></li>
                                    <li><Link to="/datenschutz" style={{color:"white"}}>Datenschutz</Link></li>
                                </ul>
                                </div>            
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
//Props Types
export default Footer;
