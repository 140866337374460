import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

//Import Component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

class Impressum extends Component {
  render() {
    return (
        <React.Fragment>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar />
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-left">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <h3>Diensteanbieter</h3>
                                    <p>
                                        Maximilian H. Wenzel<br></br>                                                             
                                        Heinrich-Goebel-Str. 9<br></br>  
                                        90552 Röthenbach a. d. Pegnitz<br></br>   
                                        Deutschland
                                    </p>
                                    
                                    <h3>Kontaktmöglichkeiten</h3>
                                    <p>
                                        <b>E-Mail-Adresse: </b>kontakt@derbadmax.de<br></br>                                                                    
                                        <b>Telefon:</b> + 49 (0) 151 426 797 26
                                    </p>
                                    
                                    <h3>Vertretungsberechtigte</h3>
                                    <p>
                                        <b>Vertretungsberechtigt:</b> Maximilian H. Wenzel                                                                    
                                    </p>
                                    
                                    <h3>Journalistisch-redaktionelle Angebote</h3>
                                    <p>
                                        <b>Inhaltlich verantwortlich:</b> Maximilian H. Wenzel (Adresse wie oben)                                                                    
                                    </p>
                                    
                                    {/*  
                                    <h3>Angaben zum Unternehmen</h3>
                                    <p>
                                        <b>Umsatzsteuer Identifikationsnummer (USt-ID):</b> DE1234567890                                                                   
                                    </p>
                                    */}
                                    
                                    <h3>Haftungs- und Urheberrechtshinweise</h3>
                                    <p>
                                        <b>Haftungsausschluss:</b> Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder Widerrufsbelehrungen für Verbraucher) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
                                                                                                         
                                    </p>
                                    
                                    <h3>Bildnachweise</h3>
                                    <p>
                                        <b>Bildquellen und Urheberrechtshinweise:</b> <br></br>
                                        Beton Vivet<br></br>
                                        Project Floors<br></br>                                                                 
                                    </p>
                                    
                                    

                                    
                                    
                                    
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    
                </div>
            </section>
            
            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </React.Fragment>
    );
  }
}




//Props Types
Impressum.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Impressum.defaultProps = {
    SectionbgTitle: "Impressum",
    sectionTitle: "Impressum",
    

  
};

export default Impressum;
