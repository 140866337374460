import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import { Accordion, AccordionItem } from "react-sanfona";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

class FAQ extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="faq ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="faq-content">
                            <div className="row">
                                <div className="col-lg-4">
                                    <Link to={this.props.ContentLink}>
                                        <div className="content-box color-effect-1">
                                            <h3>{this.props.ContentTitle}</h3>

                                            <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                                <div className="box-icon"><Icofont icon="icofont-share-alt" /></div>
                                            </div>

                                            <p>{this.props.ContentDescription}</p>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-lg-8">
                                    <Accordion
                                        rootTag="div"
                                        className="panel-group"
                                    >
                                        {this.props.faqData.map(item => {
                                            return (
                                                <AccordionItem
                                                    key={item}
                                                    title={item.title}
                                                    expanded={true}
                                                    expandedClassName=""
                                                    className="panel-title panel panel-default"
                                                    titleTag="a"
                                                    titleClassName=""
                                                >
                                                    <div>
                                                        <div className="panel-body">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </AccordionItem>
                                            );
                                        })}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
FAQ.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    ContentLink: PropTypes.string,
    ContentTitle: PropTypes.string,
    ContentDescription: PropTypes.string,
    faqData: PropTypes.array
};

//Default Props
FAQ.defaultProps = {
    SectionbgTitle: "FAQ",
    sectionTitle: "Häufige Fragen und Antworten",
    sectionDescription:
        "",
    
    ContentTitle: "Ihre Frage nicht dabei ?",
    ContentDescription: "Bei weiteren Fragen nehmen Sie bitte Kontakt mit uns auf.",
    ContentLink: "/#contact",

        faqData: [
        {
            title: "Was ist mit den alten Fliesen an der Wand ?",
            description: "Für eine Wandbeschichtung schlagen wir die alten Fliesen nicht ab, sondern beschichten diese mit attraktivem, langlebigem Dekorputz. Von der Raumfläche geht kaum etwas verloren, da wir eine sehr dünne, aber feuchtraumgeeignete Putzschicht auftragen.",
        },
        {
            title: "Wie läuft ein Besichtigungstermin ab ?",
            description: "Wir besichtigen Ihr Badezimmer und prüfen den Zustand und Veränderungsmöglichkeiten. Danach können wir Ihnen ein Repertoire von verfügbaren Materialien zeigen und ein unverbindliches Angebot erstellen.",
        },
        {
            title: "Wie lange brauchen Sie um anzufangen ?",
            description: "Das kommt auf die aktuelle Auftragslage an, jedoch bemühen wir uns Aufträge innerhalb von 2-4 Wochen zu beginnen.",
        },
        {
            title: "Wie lange ist bei mir zu Hause Baustelle ?",
            description: "Das variert je nach vereinbartem Aufwand. Für ein Standardbad benötigen wir für eine komplette Sanierung mit Standardmaterial maximal 2 Wochen. Wenn keine Rohre verlegt werden müssen, kann die Arbeit auch in 5 Tagen geschehen.",
        },
        {
            title: "Wo kann ich mir Muster von Böden anschauen?",
            description: "Vor-Ort können wir Ihnen von jedem Material Muster zeigen. Zum ansehen, zum anfühlen.",
        },
        {
            title: "Was ist mit den Bodenfliesen ?",
            description: "Die alten Bodenfliesen können im Normalfall im Bad bleiben und werden mit einem geringen Aufbau mit dem neuen Bodenbelag aufgesetzt.",
        }
    ]
};

export default FAQ;
