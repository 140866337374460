import React, { Component } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { MDBContainer, MDBRow } from "mdbreact";
import Lightbox from "react-image-lightbox";


const images = [
    require("../assets/img/wand01big.jpg"),
    require("../assets/img/wand02big.jpg"),
    require("../assets/img/wand03big.jpg"),
    require("../assets/img/wand04big.jpg"),
    require("../assets/img/wand05big.jpg"),
    require("../assets/img/wand06big.jpg"),
    require("../assets/img/wand07big.jpg"),
    require("../assets/img/wand08big.jpg"),
    require("../assets/img/wandfarbpalettebig.jpg")
  ];
  
  const smallImages = [
    require("../assets/img/wand01small.jpg"),
    require("../assets/img/wand02small.jpg"),
    require("../assets/img/wand03small.jpg"),
    require("../assets/img/wand04small.jpg"),
    require("../assets/img/wand05small.jpg"),
    require("../assets/img/wand06small.jpg"),
    require("../assets/img/wand07small.jpg"),
    require("../assets/img/wand08small.jpg"),
    require("../assets/img/wandfarbpalettesmall.jpg")
  ];

class Waende extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex: 0,
          isOpen: false
        };
      }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <React.Fragment>
                <section id="waende" className="our-works ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">
                                            {this.props.SectionbgTitle}
                                        </span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <MDBContainer>
                        <div className="mdb-lightbox no-margin">
                            <MDBRow>
                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[0]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[1]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[2]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[3]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[4]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[5]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">
                                                 
                                                <ul className="icon">
                                                    <li>
                                                        <span 
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <Icofont icon="icofont-search-2"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </MDBRow>
                        </div>
                        {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            imageTitle={photoIndex + 1 + "/" + images.length}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                            }
                            onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                            }
                        />
                        )}
                    </MDBContainer>
                </section>
            </React.Fragment>
        );
    }
}
//Props Types
Waende.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Waende.defaultProps = {
    SectionbgTitle: "Wände",
    sectionTitle: "Wände",
    sectionDescription:
        "Wir verwenden für die Beschichtung von Wänden Carament von Beton Vivet. Das ist Feinzement und eine Variante von Beton Ciré (franz. gewachster Beton) und besteht aus einem mineralischen Pulver sowie einer Harzmilch. Dieser Mikrozement kann auf alte Fliesen aufgebracht werden, ohne dass diese vorher entfernt werden müssen. Das Material baut in sich keine Spannungen auf und ist deshalb auch nicht anfällig für Risse. Es entstehen ruhige und harmonische Flächen mit einer einzigartigen Marmorierung die sich auch noch toll anfühlen. Fugenlose Wände sind wasserbeständig und lassen sich viel leichter reinigen, da sie durch die spezielle Versiegelung eine geschlossene porenlose Oberfläche aufweisen. Schimmel hat hier keine Chance. ",
};

export default Waende;
