import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Bad extends Component {
  render() {
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    
                                                                        
                                    <p>
                                        Das Badezimmer ist heutzutage viel mehr als nur eine Nasszelle. Es gehört zum Lebensraum. Es zeigt die Persönlichkeit seines Besitzers. Es ist ein Ort der Entspannung, des Auftankens, die Quelle von Schönheit und Lebenskraft. Wir erfrischen uns mit einer belebenden Dusche und entspannen bei einem wärmenden Bad. Das Badezimmer ist Romantik, Duft und Liebe.                       
                                    </p>
                                    
                                    <p>
                                        Wir von BADMAX sind Spezialisten für moderne Badgestaltung. Ob fugenlose Wandbeschichtung, ansprechende Bodenbearbeitung mit innovativen, langlebigen Materialen, Raindance, begehbare Dusche, freistehende Badewanne - wir beraten Sie ausführlich um Ihr Traumbad Wirklichkeit werden zu lassen.                         
                                    </p>
                                    
                                                                        
                                    
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    
                </div>
            </section>
            
            
        </React.Fragment>
    );
  }
}




//Props Types
Bad.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Bad.defaultProps = {
    SectionbgTitle: "Badezimmer",
    sectionTitle: "Ihre persönliche Wohlfühloase",
    

  
};

export default Bad;
