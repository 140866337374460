import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import Banner from "../components/Banner";
import Bad from "../components/Bad";
import Ablauf from "../components/Ablauf";
import Waende from "../components/Waende";
import Boeden from "../components/Boeden";
import WaendeVideo from "../components/WaendeVideo";
import BoedenVideo from "../components/BoedenVideo";
import WaendeBar from "../components/WaendeBar";
import BoedenBar from "../components/BoedenBar";
import FAQ from "../components/FAQ";
import BoedenFarben from "../components/BoedenFarben";
import WaendeFarben from "../components/WaendeFarben";
import Kontakt from "../components/Kontakt";
import Footer from "../components/Footer";

class Home extends Component {
  render() {
    return (
        <React.Fragment>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar pageName="home" />
            {/* Banner: src/components/banner/Banner */}
            <Banner />
            {/* Bad: src/components/Bad.jsx */}
            <Bad />
            {/* WaendeBar: src/components/WaendeBar.jsx */}
            <WaendeBar />
            {/* Waende: src/components/Waende.jsx */}
            <Waende />
            {/* WaendeVideo: src/components/WaendeVideo.jsx */}
            <WaendeVideo />
            {/* WaendeFarben: src/components/WaendeFarben.jsx */}
            <WaendeFarben />
            {/* BoedenBar: src/components/BoedenBar.jsx */}
            <BoedenBar />
            {/* Boeden: src/components/Boeden.jsx */}
            <Boeden />
            {/* BoedenVideo: src/components/BoedenVideo.jsx */}
            <BoedenVideo />
            {/* BoedenFarben: src/components/BoedenFarben.jsx */}
            <BoedenFarben />
            {/* Ablauf: src/components/Ablauf.jsx */}
            <Ablauf />
            {/* FAQ: src/components/FAQ.jsx */}
            <FAQ />
            {/* Kontakt: src/components/Kontakt.jsx */}
            <Kontakt />
            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </React.Fragment>
    );
  }
}

export default Home;
